<template>
    <div></div>
</template>

<script>
import setCookie from "../lib/set-cookie";
import jwtDecode from "jwt-decode";
import deleteCookie from "../lib/delete-cookie";

export default {
    name: "PageSSO",
    computed: {
        jwt() {
            return this.$route.params.jwt;
        },
        profile() {
            return this.$store.state.USER.profile;
        }
    },
    beforeMount() {
        let decoded;

        try {
            decoded = jwtDecode(this.jwt);
        } catch (error) {
            this.$router.push({name: 'dashboard-overview'});
            this.$store.dispatch('toast/danger', {
                title: this.$t('toast.danger.error'),
                message: this.$t('toast.danger.sign-in-as-user')
            });
            return;
        }

        let user = {
            key: null,
            secret: null,
            email: decoded.eusdp.email,
            firstName: decoded.eusdp.firstName,
            lastName: decoded.eusdp.lastName,
            token: this.jwt,
            partyUniqueId: decoded.eusdp.partyUniqueId,
            awsAccessKeyId: null,
            awsSecretAccessKey: null
        };

        deleteCookie('user');
        setCookie('user', JSON.stringify(user), {expires: 86400});

        this.$store.dispatch('USER/GET_PROFILE')
            .then(() => {
                user.awsAccessKeyId = this.profile.aws.key;
                user.awsSecretAccessKey = this.profile.aws.secret;

                deleteCookie('user');
                setCookie('user', JSON.stringify(user), {expires: 86400});

                this.$store.commit('USER/populateUser', user, {root: true});
                this.$router.push({name: 'dashboard-overview'});
                this.$store.dispatch('toast/success', {
                    title: this.$t('toast.success.success'),
                    message: this.$t('toast.success.sign-in-as-user', {email: user.email})
                });
            })
            .catch(() => {
                this.$router.push({name: 'dashboard-overview'});
                this.$store.dispatch('toast/danger', {
                    title: this.$t('toast.danger.error'),
                    message: this.$t('toast.danger.sign-in-as-user')
                });
            });
    }
}
</script>

<style scoped>

</style>